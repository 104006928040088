import React, { useCallback, useMemo, useEffect, useState, useReducer, useRef } from 'react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Button from 'components/Button';
import _ from 'lodash';
import { Col, Row } from 'reactstrap';
import ExportToExcel from 'lib/ExportToExcel';
import { listReducer, } from 'lib/helper';
import { postSearchApi } from 'api/chat';
import Matrix from 'components/Matrix';
import Loading from 'components/Loading';

const RoleALlocationTab = (props) => {
  const { initalDataLoaded, client, name, website, businessDetails, missionStatement, 
    strategyArena, strategyVehicle, strategyDifferentiator, 
    strategyStaging, strategyEconomicLogic, designCriteria, blueprint,
    functions, organizationUnits, roleAllocation, setRoleAllocation, roles } = props;

  const [isGeneratingStaffing, setIsGeneratingRoleAllocation] = useState(false);
  const [isEvaluatingStaffing, setIsEvaluatingStaffing] = useState(false);
  const [staffingEvaluation, setStaffingEvaluation] = useState({});
  const [isLoading, setIsLoading] = useReducer(listReducer, []);

  const debouncedSetRoleAllocation = useCallback(
    _.debounce((update) => setRoleAllocation(update), 300),
    [setRoleAllocation]
  );

  useEffect(() => {
    if (organizationUnits.length === 0 || roles.length === 0) {
      return;
    }
    if (!initalDataLoaded) {
      return;
    }
  
    const orgUnitIds = organizationUnits.map(unit => unit.id);
    // const staffingMatrixUnitIds = roleAllocation.map(unit => unit.id);
    const currentRoleIds = roles.map(role => role.id);
    // const staffingMatrixRoleIds = roleAllocation[0]?.columns.map(role => role.id) || [];
  
    // Creating a deep copy to prevent mutating state directly
    const newRoleAllocation = _.cloneDeep(roleAllocation);
  
    // Add or update missing units
    organizationUnits.forEach(unit => {
      const existingUnit = newRoleAllocation.find(row => row.id === unit.id);
  
      if (existingUnit) {
        // Update name or abbreviation if they differ
        if (existingUnit.name !== unit.name || existingUnit.abbreviation !== `${unit.emoji} ${unit.abbreviation}`) {
          existingUnit.name = unit.name;
          existingUnit.abbreviation = `${unit.emoji || ''} ${unit.abbreviation || ''}`;
        }
      } else {
        // Add new unit
        newRoleAllocation.push({
          id: unit.id,
          // abbreviation: `${unit.emoji} ${unit.abbreviation}`,
          // name: unit.name,
          columns: [],
        });
      }
    });
  
    // Remove extra units
    newRoleAllocation.forEach((unit, index) => {
      if (!orgUnitIds.includes(unit.id)) {
        newRoleAllocation.splice(index, 1);
      }
    });
  
    // Add or update missing roles
    roles.forEach(role => {
      newRoleAllocation.forEach(unit => {
        const existingColumn = unit.columns.find(column => column.id === role.id);
  
        if (existingColumn) {
          // Update name or abbreviation if they differ
          if (existingColumn.name !== role.name || existingColumn.abbreviation !== `${role.emoji} ${role.abbreviation}`) {
            existingColumn.name = role.name;
            existingColumn.abbreviation = `${role.emoji || ''} ${role.abbreviation || ''}`;
          }
        } else {
          // Add new role column
          unit.columns.push({
            id: role.id,
            // abbreviation: `${role.emoji || ''} ${role.abbreviation || ''}`,
            // name: role.name,
            value: undefined,
          });
        }
      });
    });
  
    // Remove extra roles
    newRoleAllocation.forEach(unit => {
      unit.columns = unit.columns.filter(column => currentRoleIds.includes(column.id));
    });
  
    // Update state only if there are changes
    if (!_.isEqual(roleAllocation, newRoleAllocation)) {
      debouncedSetRoleAllocation({ type: 'overwrite', payload: newRoleAllocation });
    }
  }, [initalDataLoaded, organizationUnits, roles, roleAllocation, debouncedSetRoleAllocation]);
  
  const rowData = useMemo(() => {
    if (Array.isArray(roleAllocation) && roleAllocation.length > 0) {
      let rowData = [];
      for (const roleAllocationUnit of roleAllocation) {
        const unitData = organizationUnits.find(unit => unit.id === roleAllocationUnit.id);
        rowData.push({
          id: roleAllocationUnit.id,
          name: unitData.name,
          abbreviation: unitData.abbreviation,
          columns: [],
        });
        for (const role of roleAllocationUnit.columns) {
          const roleData = roles.find(roleData => roleData.id === role.id);
          rowData[rowData.length - 1].columns.push({
            id: role.id,
            name: roleData.name,
            abbreviation: roleData.abbreviation,
            value: role.value,
          });
        }
      }
      return rowData;
    }
    return [];
  }, [roleAllocation, organizationUnits, roles]);

  const KeepRowButtonRenderer = ({ api, node, data }) => {
    const handleTransfer = () => {
      setRoleAllocation({ type: 'prepend', payload: [data] });
      setStaffingEvaluation({ ...staffingEvaluation, recommended_functions: staffingEvaluation.recommended_functions.filter(func => func.id !== data.id) });
  
      setTimeout(() => {
        api.setRowData(data);
      }, 0);
    };
  
    return (
      <span onClick={handleTransfer} className="button__icon button__icon-green material-symbols-outlined">
        add_circle
      </span>
    );
  };

  const generateRoleAllocation = async () => {
    if (isGeneratingStaffing) return;
    roleAllocation.forEach((unit) => {
      if (unit === undefined || !('id' in unit)) {
        return;
      }
      setIsLoading({ type: 'add_to_list', payload: unit.id });
    });
    setIsGeneratingRoleAllocation(true);
    const apiPayload = {
        'prompt_template': 'generate_role_allocation',
        'client': client,
        'business_name': name ? name : '', 
        'business_website': website ? website : '',
        'mission_statement': missionStatement ? missionStatement : '',
        'industry': businessDetails.industry ? businessDetails.industry : '',
        'strategy_arena': strategyArena ? strategyArena : '',
        'strategy_vehicle': strategyVehicle ? strategyVehicle : '',
        'strategy_differentiators': strategyDifferentiator ? strategyDifferentiator : '',
        'strategy_staging': strategyStaging ? strategyStaging : '',
        'strategy_economic_logic': strategyEconomicLogic ? strategyEconomicLogic : '',
        'design_criteria': designCriteria ? designCriteria : [],
        'blueprint': blueprint ? blueprint : {},
        'functions': functions ? functions : [],
        'organization_units': organizationUnits ? organizationUnits : [],
        'roles': roles ? roles : [],
        'role_allocation': roleAllocation ? roleAllocation : [],
    };

    try {
      const apiResponse = await postSearchApi(apiPayload);
      const generatedStaffingAssignments = apiResponse.message;

      try {
        generatedStaffingAssignments.forEach((assignment) => {
          // verify that each function and unit exists
          const functionExists = functions.find(func => String(func.id) === String(assignment.function_id));
          const unitExists = roles.find(unit => String(unit.id) === String(assignment.unit_id));
          if (!functionExists || !unitExists) {
            console.error('Function or unit does not exist:', assignment);
            return;
          }
          setRoleAllocation({
            type: 'set_staffing_role',
            payload: {
              function_id: assignment.function_id,
              unit_id: assignment.unit_id,
              staffing_role: assignment.staffing_role,
            },
          });
        });
      } catch (error) {
          console.error('Error parsing API response:', error);
      }

    } catch (error) {
        console.error('Error generating design criteria:', error);
    }
    setIsLoading({ type: 'overwrite', payload: [] });
    setIsGeneratingRoleAllocation(false);
  };

const evaluateStaffing = async (
  ) => {
    if (isGeneratingStaffing) return;
    setIsEvaluatingStaffing(true);

    const apiPayload = {
        'prompt_template': 'evaluate_staffing',
        'client': client,
        'business_name': name ? name : '', 
        'business_website': website ? website : '',
        'mission_statement': missionStatement ? missionStatement : '',
        'industry': businessDetails.industry ? businessDetails.industry : '',
        'strategy_arena': strategyArena ? strategyArena : '',
        'strategy_vehicle': strategyVehicle ? strategyVehicle : '',
        'strategy_differentiators': strategyDifferentiator ? strategyDifferentiator : '',
        'strategy_staging': strategyStaging ? strategyStaging : '',
        'strategy_economic_logic': strategyEconomicLogic ? strategyEconomicLogic : '',
        'design_criteria': designCriteria ? designCriteria : [],
        'blueprint': blueprint ? blueprint : {},
        'functions': functions ? functions : [],
        'organization_units': roles ? roles : [],
    };

    try {
      const apiResponse = await postSearchApi(apiPayload);
      const unitsEvaluation = apiResponse.message;

      try {
        setStaffingEvaluation(unitsEvaluation);
      } catch (error) {
          console.error('Error parsing API response:', error);
      }

    } catch (error) {
        console.error('Error generating design criteria:', error);
    }
    setIsEvaluatingStaffing(false);
  };

  const refineStaffing = async (activity) => {
    return; 
    /*console.log('Splitting activity:', activity);
    setIsLoading({ type: 'add_to_list', payload: [activity.id] });
    const apiPayload = {
      'prompt_template': 'split_orgnaniazation_unit',
      'activity_to_split': activity.activity,
      'description': activity.description,
      'business_name': name ? name : '', 
      'business_website': website ? website : '',
      'industry': businessDetails.industry ? businessDetails.industry : '',
      'num_employees': businessDetails.num_employees ? businessDetails.num_employees : '',
      'revenue': businessDetails.revenue ? getRevenueFormated(businessDetails.revenue) : '',
      'designCriteria': designCriteria ? designCriteria : [],
    };

    try {
      const apiResponse = await postSearchApi(apiPayload);
      const newdesignCriteria = apiResponse.message[0]['designCriteria'];
      return newdesignCriteria;
    } catch (error) {
      console.error('Error sending chat message:', error);
    } finally {
      setIsLoading({ type: 'remove_from_list', payload: [activity.id] });
    }*/
  }

  const validatePositiveNumber = (value) => {
    if (!value) return true;
    const number = parseFloat(value);
    return !isNaN(number) && number > 0;
  };

  return (
    <div className="define-tab">
      <div className="function-tab-header">

      </div>

      <div style={{ display: 'flex', alignItems: 'center', gap: '10px', overflow: 'visible' }}>
        <button
          onClick={() => generateRoleAllocation()}
          disabled={isGeneratingStaffing}
          className="button"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          Allocate roles
          {isGeneratingStaffing ? (
            <Loading style={{ marginLeft: 'auto', height: '35px' }} />
          ) : (
            <span className="material-symbols-outlined">neurology</span>
          )}
        </button>
        <ExportToExcel
          buttonLabel="Export Role Allocation"
          data={roleAllocation}
          fileName={`${name} Role Allocation from Reconfig`}
          sheetName={`${name} Role Allocation`}
          fieldsToExport={[
            'id',
            'name',
            'abbreviation',
            { field: 'columns', keyForObject: 'value', headerKey: 'name' },
          ]}
          className="button ms-2"
        />
      </div>

      <div className="mt-6" style={{'margin': '10px'}}>
        <h2>Allocation of roles to units</h2>
      </div>
      <Matrix
        matrix={roleAllocation}
        setMatrix={setRoleAllocation}
        isLoading={isLoading}
        validateInput={validatePositiveNumber}
      />
    </div>
  );
}

export default RoleALlocationTab;
