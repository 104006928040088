import React, { useMemo, useState, useRef, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import LoadingCellRenderer from 'components/LoadingCellRenderer';

const StaffingMatrix = ({ matrix, setMatrix, isLoading, validateInput  }) => {
  const [agGridContext, setAgGridContext] = useState({});
  const gridRef = useRef();
  const prevRowDataRef = useRef();

  useEffect(() => {
    setAgGridContext({ isLoading, prevRowData: prevRowDataRef.current });
    prevRowDataRef.current = rowData;
  }, [rowData, isLoading]);

  const columnDefs = useMemo(() => {
    const localMatrix = Array.isArray(matrix) ? matrix : [];

    return [
      { headerName: 'ID', field: 'id', editable: false, hide: true, minWidth: 100 },
      { headerName: 'Organization Unit', field: 'name', headerTooltip: getRowTooltip, editable: false, minWidth: 200 },
      ...(localMatrix[0]?.columns.map((column) => ({
        headerName: column.abbreviation,
        field: String(column.id),
        headerClass: 'ag-header-cell-center',
        cellClass: 'ag-cell-center',
        editable: true,
        minWidth: 30,
        headerTooltip: column.name,
        cellRendererFramework: LoadingCellRenderer,
        cellRendererParams: { isLoading },
      })) || []),
    ];
  }, [matrix, isLoading]);

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      gridRef.current.api.refreshCells();
    }
  }, [isLoading]);

  const rowData = useMemo(() => {
    const matrixData = Array.isArray(matrix) ? matrix : [];

    if (matrixData.length) {
      return matrixData.map((row) => {
        const rowData = { 
          id: row.id,
          abbreviation: row.abbreviation,
          name: row.name,
        };

        row.columns.forEach((column) => {
          rowData[column.id] = column.value;
        });

        return rowData;
      });
    }
    return [];
  }, [matrix]);

  // Auto-size columns after grid is ready
  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      gridRef.current.api.sizeColumnsToFit();
    }
  }, [columnDefs, rowData]);

  const handleCellValueChange = (params) => {
    if (validateInput(params.newValue)) {
      setMatrix({
        type: 'set_cell',
        payload: {
          rowId: params.data.id,
          columnId: params.colDef.field,
          value: params.newValue,
        },
      });
    } else {
      // If the value is invalid, you might want to reset it to the previous value
      params.node.setDataValue(params.colDef.field, params.oldValue);
      console.error('Invalid input: only positive numbers are allowed.');
    }
  };

  const getRowTooltip = (params) => {
    return params.data?.name;
  }
  

  return (
    (matrix && (
      <div className="mt-2 ag-theme-alpine ag-row grid-height-400">
        <AgGridReact
          ref={gridRef}
          columnDefs={columnDefs}
          rowData={rowData}
          context={agGridContext}
          tooltipShowDelay={0}
          onCellValueChanged={handleCellValueChange}
        />
      </div>
    ))
  );
};

export default StaffingMatrix;
