import React, { useCallback, useMemo, useEffect, useState, useReducer, useRef } from 'react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Button from 'components/Button';
import _, { result, split } from 'lodash';
import { Modal, ModalBody, ModalFooter, ModalHeader, Container, Col, Row } from 'reactstrap';
import SVG from 'components/SVG';
import AggridContextMenu from 'components/AggridContextMenu';
import ClassificationDropdownEditor from 'components/ClassificationDropdownEditor';
import ExportToExcel from 'lib/ExportToExcel';
import CSVWizard from 'pages/Survey/components/CSVWizard';
import { generateuniqueIshId, listReducer, transposeObject, sum } from 'lib/helper';
import { postSearchApi } from 'api/chat';
import Loading from 'components/Loading';
import LoadingCellRenderer from 'components/LoadingCellRenderer';
import ArrayCellRenderer from 'components/ArrayCellRenderer';
import DraggableListCellRenderer from 'components/DraggableListCellRenderer';
import Table from 'components/Table';
import CollapsibleEvaluationAgGrid from 'components/CollapsibleAgGrid ';
import DeleteRowButtonRenderer from 'components/ButtonRenderer/DeleteRowButtonRenderer';

const UnitTab = (props) => {
  const { client, name, website, businessDetails, missionStatement, 
    strategyArena, strategyVehicle, strategyDifferentiator, 
    strategyStaging, strategyEconomicLogic, classificationOptions,
    designCriteria, functions, organizationUnits, setOrganizationUnits, roles, 
    addNewRole, removeRole, setRoleAllocation  } = props;

  const [isGeneratingUnits, setIsGeneratingUnits] = useState(false);
  const [isEvaluatingUnits, setIsEvaluatingUnits] = useState(false);
  const [unitsEvaluation, setUnitsEvaluation] = useState({});
  const [showImport, setShowImport] = useState(false);
  const [isLoading, setIsLoading] = useReducer(listReducer, []);

  const baseColumnConfig = useMemo(() => ({
    editable: true,
    sortable: true,
    filter: true,
    resizable: true,
    flex: 1,
    cellClass: '.ag-cell-prevent-word-split',
  }), []);

  const baseColumnDefs = useMemo(() => [
    { headerName: 'ID', field: 'id', hide: true, ...baseColumnConfig },
    { headerName: '', field: 'emoji', ...baseColumnConfig, width: 30, flex: 0 },
    { headerName: 'Name', field: 'name', cellRendererFramework: LoadingCellRenderer, ...baseColumnConfig },
    { headerName: 'Abbreviation', field: 'abbreviation', cellRendererFramework: LoadingCellRenderer, ...baseColumnConfig },
    { headerName: 'Purpose', field: 'purpose', cellRendererFramework: LoadingCellRenderer, wrapText: true, ...baseColumnConfig, flex: 3 },
    // { headerName: 'Manager', field: 'managerName', editable: true, cellRendererFramework: LoadingCellRenderer, wrapText: true, ...baseColumnConfig, flex: 2 },
    { headerName: 'Manager', field: 'manager', editable: true,
      cellRendererFramework: ClassificationDropdownEditor,
      cellEditorParams: {
        options: ['', ...roles.map(role => role.name)],
        isLoading: isLoading,
      }, wrapText: true, 
      ...baseColumnConfig
    },
    { headerName: 'Classification',
      field: 'classification',
      editable: true,
      cellRendererFramework: ClassificationDropdownEditor,
      cellEditorParams: {
        options: classificationOptions.map(option => option.label),
        isLoading: isLoading,
      },
      ...baseColumnConfig
    },
    { headerName: 'Reporting To', field: 'reporting_to', cellRenderer: LoadingCellRenderer, ...baseColumnConfig },
    
  ], [baseColumnConfig]);

  const unitColumns = useMemo(() => [
    ...baseColumnDefs,
    { 
      headerName: '', 
      field: 'delete_row', 
      cellRenderer: params => <DeleteRowButtonRenderer {...params} list={organizationUnits} setList={setOrganizationUnits} />, 
      cellClass: 'delete-row-button-cell', 
      width: 50, 
      flex: 0 
    }
  ], [baseColumnDefs, organizationUnits, setOrganizationUnits]);

  const evaluationColumns = useMemo(() => [
    ...baseColumnDefs,
    { 
      headerName: '', 
      field: 'add_row', 
      cellRenderer: params => (
        <KeepRowButtonRenderer 
          {...params} 
          data={JSON.stringify(organizationUnits)} 
          setData={setOrganizationUnits} 
          evaluation={JSON.stringify(unitsEvaluation)} 
          setEvaluation={setUnitsEvaluation} 
        />
      ), 
      cellClass: 'add-row-button-cell', 
      width: 50, 
      flex: 0 
    }
  ], [baseColumnDefs, organizationUnits, setOrganizationUnits, unitsEvaluation, setUnitsEvaluation]);
  
  const rowData = useMemo(() => {
    if (Array.isArray(organizationUnits)) {
      return organizationUnits.map(unit => ({
        id: unit.id,
        name: unit.name,
        abbreviation: unit.abbreviation,
        purpose: unit.purpose,
        managerId: unit.manager?.id || '',
        managerName: unit.manager?.name || '',
        classification: unit.classification,
        reporting_to: unit.reporting_to,
        emoji: unit.emoji,
      }));
    }
    return [];
  }, [organizationUnits]);

  const evaluation_rowData = useMemo(() => {
    if (Array.isArray(unitsEvaluation?.recommended_organization_units)) {
      return unitsEvaluation?.recommended_organization_units.map(unit => ({
        id: unit.id,
        name: unit.name,
        abbreviation: unit.abbreviation,
        purpose: unit.purpose,
        managerId: unit.manager.id,
        managerName: unit.manager.name,
        classification: unit.classification,
        reporting_to: unit.reporting_to,
        emoji: unit.emoji,
      }));
    }
    return [];
  }, [unitsEvaluation]);

  const addNewUnit = async () => {
    const unitNumber = Array.isArray(organizationUnits) ? organizationUnits.length + 1 : 1;
    const unitName = `Unit ${unitNumber}`;
    const manager = addNewRole('unit_manager', `${unitName} Manager`, `R_MU${unitNumber}`, '💼', );
    const newUnit = {
      id: generateuniqueIshId(),
      name: unitName,
      abbreviation: `U${unitNumber}`,
      manager: manager,
      purpose: '',
      classification: '',
      reporting_to: '',
      emoji: '',
    };

    setOrganizationUnits({ type: 'prepend', payload: [newUnit] });

    await setRoleAllocation({
      type: 'add_row',
      payload: {
        id: newUnit.id,
        abbreviation: `${newUnit.emoji} ${newUnit.abbreviation}`,
        name: newUnit.name,
        columns: [],
      },
    });

    await setRoleAllocation({
      type: 'add_column',
      payload: {
        id: manager.id,
        abbreviation: `${manager.emoji || ''} ${manager.abbreviation || ''}`,
        name: manager.name,
        value: undefined,
      },
    });

    await setRoleAllocation({
      type: 'set_cell',
      payload: {
        rowId: newUnit.id,
        columnId: manager.id,
        value: 1,
      },
    });

    return newUnit;
};


  const KeepRowButtonRenderer = ({ api, node, data }) => {
    const handleTransfer = () => {
      setOrganizationUnits({ type: 'prepend', payload: [data] });
      setUnitsEvaluation({ ...unitsEvaluation, recommended_functions: unitsEvaluation.recommended_functions.filter(func => func.id !== data.id) });
  
      setTimeout(() => {
        api.setRowData(data);
      }, 0);
    };
  
    return (
      <span onClick={handleTransfer} className="button__icon button__icon-green material-symbols-outlined">
        add_circle
      </span>
    );
  };

  const generateUnits = async (
  ) => {
    /*
    Mandates: (1. function, 2. authority, 3. domain)  - Check Nicolay's article
    We also mentioned there could be some additional elements e.g., specification of whether a unit is cost center or Revenue center etc.
    */
    if (isGeneratingUnits) return;
    setIsGeneratingUnits(true);
    const blankRow = addNewUnit();
    setIsLoading({ type: 'add_to_list', payload: blankRow.id });

    const apiPayload = {
        'prompt_template': 'generate_organization_units',
        'client': client,
        'business_name': name ? name : '', 
        'business_website': website ? website : '',
        'mission_statement': missionStatement ? missionStatement : '',
        'industry': businessDetails.industry ? businessDetails.industry : '',
        'strategy_arena': strategyArena ? strategyArena : '',
        'strategy_vehicle': strategyVehicle ? strategyVehicle : '',
        'strategy_differentiators': strategyDifferentiator ? strategyDifferentiator : '',
        'strategy_staging': strategyStaging ? strategyStaging : '',
        'strategy_economic_logic': strategyEconomicLogic ? strategyEconomicLogic : '',
        'design_criteria': designCriteria ? designCriteria : [],
        'functions': functions ? functions : [],
        'organization_units': organizationUnits ? organizationUnits : [],
        'roles': roles ? roles : [],
    };

    try {
      const apiResponse = await postSearchApi(apiPayload);
      const generatedUnits = apiResponse.message;

      try {
        setOrganizationUnits({ type: 'overwrite', payload: generatedUnits } );
        setIsGeneratingUnits(false);
      } catch (error) {
          console.error('Error parsing API response:', error);
          setIsGeneratingUnits(false);
      }

    } catch (error) {
        console.error('Error generating design criteria:', error);
    }
    setIsGeneratingUnits(false);
    setIsLoading({ type: 'remove_from_list', payload: blankRow.id });
    setOrganizationUnits({ type: 'remove', payload: blankRow });
  };

const evaluateUnits = async (
  ) => {
    if (isGeneratingUnits) return;
    setIsEvaluatingUnits(true);

    const apiPayload = {
        'prompt_template': 'evaluate_organization_units',
        'client': client,
        'business_name': name ? name : '', 
        'business_website': website ? website : '',
        'mission_statement': missionStatement ? missionStatement : '',
        'industry': businessDetails.industry ? businessDetails.industry : '',
        'strategy_arena': strategyArena ? strategyArena : '',
        'strategy_vehicle': strategyVehicle ? strategyVehicle : '',
        'strategy_differentiators': strategyDifferentiator ? strategyDifferentiator : '',
        'strategy_staging': strategyStaging ? strategyStaging : '',
        'strategy_economic_logic': strategyEconomicLogic ? strategyEconomicLogic : '',
        'design_criteria': designCriteria ? designCriteria : [],
        'functions': functions ? functions : [],
        'organization_units': organizationUnits ? organizationUnits : [],
        'roles': roles ? roles : [],
    };

    try {
      const apiResponse = await postSearchApi(apiPayload);
      const unitsEvaluation = apiResponse.message;

      try {
        setUnitsEvaluation(unitsEvaluation);
      } catch (error) {
          console.error('Error parsing API response:', error);
      }

    } catch (error) {
        console.error('Error generating design criteria:', error);
    }
    setIsEvaluatingUnits(false);
  };

  const refineUnits = async (activity) => {
    return; 
    /*console.log('Splitting activity:', activity);
    setIsLoading({ type: 'add_to_list', payload: [activity.id] });
    const apiPayload = {
      'prompt_template': 'split_orgnaniazation_unit',
      'activity_to_split': activity.activity,
      'description': activity.description,
      'business_name': name ? name : '', 
      'business_website': website ? website : '',
      'industry': businessDetails.industry ? businessDetails.industry : '',
      'num_employees': businessDetails.num_employees ? businessDetails.num_employees : '',
      'revenue': businessDetails.revenue ? getRevenueFormated(businessDetails.revenue) : '',
      'designCriteria': designCriteria ? designCriteria : [],
    };

    try {
      const apiResponse = await postSearchApi(apiPayload);
      const newdesignCriteria = apiResponse.message[0]['designCriteria'];
      return newdesignCriteria;
    } catch (error) {
      console.error('Error sending chat message:', error);
    } finally {
      setIsLoading({ type: 'remove_from_list', payload: [activity.id] });
    }*/
  }

  const handleImportSubmit = useCallback((data) => {
    setShowImport(false);
    let newUnits = [];
    data.rows.forEach((row) => {
      let newRow = {
        id: generateuniqueIshId(),
        name: row[data.name],
        abbreviation: row[data.abbreviation],
        manager: row[data.manager],
        purpose: row[data.purpose],
        classification: row[data.classification],
        reporting_to: row[data.reporting_to],
      }
      if (newUnits.find(f => f.id === newRow.id)) {
        return;
      }
      newUnits.push(newRow);
    })

    setOrganizationUnits({ type: data.write_mode, payload: newUnits });
  }, [setOrganizationUnits]);

  return (
    <div className="define-tab">
      <div className="function-tab-header">
      </div>
      
      <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          <button onClick={() => generateUnits()} disabled={isGeneratingUnits} className="button" 
          style={{ display: 'flex', alignItems: 'center' }}>
            Generate units
            <span className="material-symbols-outlined">
              neurology
            </span>
          </button>
          <button onClick={() => setShowImport(true)} className="button">
            Import Organization Units
            <span className="material-symbols-outlined">
              upload
            </span>
          </button>
          <ExportToExcel 
            buttonLabel='Export Organziation Units' 
            data={organizationUnits} 
            fileName={name + ' Organization Units from Reconfig'} 
            sheetName={name + ' Organization Units'} 
            fieldsToExport={['name', 'abbreviation', 'purpose', 'classification', 'reporting_to']}
            className={'button'}
          />
          <Button
            color="primary"
            onClick={addNewUnit}
            text="Add Organization Unit"
            className="button"
            icon="plus.svg#plus"
          />
          <Modal isOpen={showImport} toggle={() => setShowImport(false)}>
            <ModalHeader>Import Organization Units</ModalHeader>
            <CSVWizard
              toggle={() => setShowImport(false)}
              handleSubmit={handleImportSubmit}
              specifyColumns={[
                { key: 'name', label: 'Name', placeholder: 'Name', },
                { key: 'abbreviation', label: 'Abbreviation', placeholder: 'Abbreviation' },
                { key: 'purpose', label: 'purpose', placeholder: 'purpose' },
                { key: 'manager', label: 'Manager', placeholder: 'Manager' },
                { key: 'classification', label: 'Classification', placeholder: 'Classification' },
                { key: 'reporting_to', label: 'Reporting To', placeholder: 'Reporting To' },
              ]}
            />
          </Modal>
      </div>
      <CollapsibleEvaluationAgGrid 
        objectName="Organization Units"
        evaluation={unitsEvaluation?.alignment_evaluation}
        evaluationQuality={unitsEvaluation?.alignment_evaluation_quality}
        coverage={unitsEvaluation?.coverage_evaluation}
        coverageQuality={unitsEvaluation?.coverage_evaluation_quality}
        columnDefs={evaluationColumns}
        rowData={evaluation_rowData}
        evaluationFunction={evaluateUnits}
        contextMenuGeneration={refineUnits}
        isLoading={isLoading}
        isEvaluating={isEvaluatingUnits}
        setIsEvaluating={setIsEvaluatingUnits}
      />
      <div className="mt-6" style={{'margin': '10px'}}>
        <h2>Organization Units</h2>
      </div>
      <Table
        objectName="Unit"
        listTitle="Organization Units"
        list={organizationUnits}
        setList={setOrganizationUnits}
        columnDefs={unitColumns}
        rowData={rowData}
        contextMenuGeneration={refineUnits}
        isLoading={isLoading}
      />
    </div>
  );
}

export default UnitTab;
